import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Input from '../../components/Input';

import Header from '../../components/Header';

import { Container } from './styles';
import api from '../../services/api';

interface IParams {
  id: string;
}
interface IOrcamentos {
  id: string;
  empresa: string;
  orcamento: number;
  date: string;
  finished: number;
}

const Cadastro: React.FC = () => {
  const [ orcamentos, setOrcamentos ] = useState<IOrcamentos>();
  const history = useHistory();
  // const [ on, setOn ] = useState(0);
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();

  useEffect(() => {
    api.get(`/show/${params.id}`).then(response => {

      setOrcamentos(response.data);
    })
  }, [params.id]);

  // const changeHandle = useCallback((on) => {
  //   if (on === 0) {
  //     setOn(1);
  //   } else {
  //     setOn(0);
  //   }
  // }, []);

  const handleSubmit = useCallback(async (data: IOrcamentos) => {
    try {
      const schema = Yup.object().shape({
        empresa: Yup.string().required('Nome empresa obrigatório.'),
        orcamento: Yup.number().required('Numero orçamento obrigatório.'),
        // date: Yup.date().required('Data entrega obrigatória.'),
      });
      // console.log(data)
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post(`/updateAll/${params.id}`, data);

      history.goBack();
    } catch (err) {
      console.log(err);
    }
  }, [history, params.id]);  

  return (
    <>
    <Header />
    <Container>
      <h2>Cadastrar novo orçamento</h2>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <label>Nome empresa</label>
        <Input type="text" name="empresa" maxLength={21} defaultValue={orcamentos?.empresa} />

        <label>Número orçamento</label>
        <Input type="number" name="orcamento" defaultValue={orcamentos?.orcamento} />

        {/* <label>Data entrega</label>
        <Input type="date" name="date" />
          
        <label>Marque se o pedido já esta finalizado</label>
        <Input type="checkbox" name="finished" value={on} onChange={changeHandle} /> */}

        <button type="submit">Alterar Cadastro</button>
      </Form>
    </Container>
    </>
  )
};

export default Cadastro;