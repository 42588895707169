import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';

import Global from './styles/themes/global';

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Routes />
      </Router>

      <Global />
    </>
  );
}

export default App;
