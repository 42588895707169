import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import Header from '../../components/Header';
import Box from '../../components/Box';

import { Container } from './styles';

interface IOrcamentos {
  id: string;
  empresa: string;
  orcamento: number;
  date: string;
  finished: number;
  nf: number;
}

const Dashboard: React.FC = () => {
  const [ display, setDisplay ] = useState('block');
  const [ orcamentos, setOrcamentos ] = useState<IOrcamentos[]>([]);

  useEffect(() => {
      api.get('/').then(response => {
        setDisplay('none');
        setOrcamentos(response.data);
      })
  }, [orcamentos])

  return (
    <>
      <Header />
      <Container>
        <h2>Pedidos</h2>

        <div style={{display: `${display}`}}><h2>Loading...</h2></div>

      <div className='box'>
        {orcamentos.map(orcamento => (
          <Box key={orcamento.id}
          id={orcamento.id}
          title={orcamento.empresa}
          orcamento={orcamento.orcamento}
          date={orcamento.date}
          finished={ orcamento.finished === 0 ? (false) : (true)}
          nf={ orcamento.nf === 0 ? (false) : (true)}
        />
        ))}
      </div>
      </Container>
    </>
  )
};

export default Dashboard;