import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  height: calc(100vh - 38px);
  border-radius: 4px;
  margin: 0 auto;
  
  h2 {
    display: flex;
    justify-content: center;
    padding: 5px 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  table {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    background: #FFF;
    color: #626A6D;
    border-radius: 4px;

    thead tr th {
      font-size: 20px;
      font-weight: 500;
      padding: 2px 10px;
      margin: 0 -10px;
      border: 1px solid #626A6D;
    }

    tbody tr td {
      font-size: 18px;
      padding: 4px 0;
      border-bottom: 1px solid #262629;

      button {
        background: #626A6D;
        border: 0;
        border-radius: 3px;
        transition: 0.4s;
        padding: 2px 5px;
        color: #FFF;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    tbody tr:hover {
      transition: 0.4s;
    }

    tbody tr:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
`;