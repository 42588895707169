import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  max-width: 1000px;
  height: 36px;
  background: #FFF;
  margin: 0 auto;
  margin-top: 2px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px #000;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    
    li {
      margin-left: 20px;
      
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 21px;
        color: #626A6D;
        transition: 0.4s;

        &:hover {
          opacity: 0.2;
        }

        svg {
          margin-right: 3px;
        }
      }
    }
  }
`;