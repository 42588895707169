import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import Cadastro from '../pages/Cadastro';
import Historico from '../pages/Historico';
import Update from '../pages/Update';

const Routes: React.FC = () => (
  <Switch>
    <Route path='/' exact component={Dashboard} />
    <Route path='/cadastro' component={Cadastro} />
    <Route path='/historico' component={Historico} />
    <Route path='/update/:id' component={Update} />
  </Switch>
);

export default Routes;