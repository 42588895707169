import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  height: calc(100vh - 38px);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  
  h2 {
    display: flex;
    justify-content: center;
    padding: 5px 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  div.box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;