import React from 'react';
import { FiLayers, FiPlus, FiList } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Container } from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <ul>
        <li>
          <Link to="/">
            <FiLayers size={27} />
            Painel
          </Link>
        </li>
        <li>
          <Link to="/cadastro">
            <FiPlus size={27} />
            Cadastrar Pedido
          </Link>
        </li>
        <li>
          <Link to="/historico">
            <FiList size={27} />
            Histórico
          </Link>
        </li>
      </ul>
    </Container>
  )
};

export default Header;