import React from 'react';
import { FiXCircle } from 'react-icons/fi';

import api from '../../services/api';

import { Container } from './styles';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

interface IRequest {
  id: string;
  title: string;
  orcamento: number;
  date: string;
  finished: boolean;
  nf: boolean;
}

const Box: React.FC<IRequest> = ({ id, title, orcamento, date, finished, nf }) => {
  const history = useHistory();

  async function handleExclude(id: string) {
    await api.post(`/delete/${id}`);
  };

  async function handleUpdate(id: string) {
    await api.post(`/update/${id}`);
  };

  async function handleUpdateNf(id: string) {
    await api.post(`/updateNf/${id}`);
  };

  async function handleUpdateAll(id: string) {
    // await api.post(`/updateAll/${id}`);
    history.push(`/update/${id}`);
  };

  const formatedDate = DateTime.fromISO(date).plus({ 'days': 1 }).toISO() //.toLocaleDateString('pt-br');

  return (
    <Container finished={finished} nf={nf} onDoubleClick={() => handleUpdateAll(id)}>
      <div className='title'>
        <strong>
          {title}
        </strong>
        <button type='button' onClick={() => handleExclude(id)}>
          <FiXCircle size={22} color='#e249' />
        </button>
      </div>
      <div className='orcamento'>
        <strong>Orçamento: {orcamento}</strong>
      </div>
      <div className='date'>
        <p>
          {new Date(formatedDate).toLocaleDateString('pt-BR')}
        </p>
        <div>
          <div>
            <label>NF</label>
            <input type="checkbox" defaultChecked={nf} onClick={() => handleUpdateNf(id)} />
          </div>
          <div>
            <label>OK</label>
            <input type="checkbox" defaultChecked={finished} onClick={() => handleUpdate(id)} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Box;