import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  height: calc(100vh - 38px);
  border-radius: 4px;
  margin: 0 auto;

  h2 {
    display: flex;
    justify-content: center;
    padding: 5px 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 40px;
    }
    
    label:first-child {
      margin-top: 20px;
    }

    button {
      font-size: 25px;
      border: 0;
      padding: 10px;
      border-radius: 8px;
      width: 200px;
      text-transform: uppercase;
      margin-top: 50px;
      transition: 0.4s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;