import styled, { css } from 'styled-components';

interface IRequest {
  finished: boolean;
  nf: boolean;
}

export const Container = styled.div<IRequest>`
  width: 300px;
  height: 120px;
  border-radius: 6px;
  color: #626A6D;
  margin-bottom: 12px;
  background: #FFF;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
  }

  ${props => props.finished && props.nf && css`
    background: #defedf;
  `}

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;

    strong {
      font-size: 22px;
    }

    button {
      background: transparent;
      border: 0;
      transition: 0.4s;

      svg {
        display: flex;
        align-items: center;
      }

      &:hover {
        opacity: 0.2;
      }
    }
  }

  div.orcamento {
    strong {
      font-size: 20px;
      padding: 5px 20px;
    }
  }

  div.date {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;

    p {
      font-size: 20px;
    }

    >div {
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        margin: 6px;

        input {
          width: 20px;
          height: 20px;
          cursor: pointer;          
        }
      }
    }
  }
`;