import styled, { css } from 'styled-components';

interface InputProps {
  type: string;
}

export const Container = styled.div<InputProps>`
  background: #FFF;
  border-radius: 10px;
  width: 50%;
  padding: 2px 20px;
  height: 40px;
  margin-top: 10px;

  display: flex;
  align-items: center; 

  input {
    background: transparent;
    width: 100%;
    font-size: 25px;
    flex: 1;
    border: 0;
    text-align: center;
  }

  ${props => props.type === 'checkbox' && css`
    background: transparent;

    input {
      width: 30px;
      height: 30px;
    }
  `}
`;