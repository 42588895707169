import React, { useState, useEffect} from 'react';
import api from '../../services/api';

import Header from '../../components/Header';

import { Container } from './styles';

interface IOrcamentos {
  id: string;
  empresa: string;
  orcamento: number;
  date: string;
}

const Historico: React.FC = () => {
  const [ count, setCount ] = useState(0);
  const [ display, setDisplay ] = useState('block');
  const [ orcamentos, setOrcamentos ] = useState<IOrcamentos[]>([]);

  useEffect(() => {
    api.get('/historico').then(response => {
      setDisplay('none');
      setOrcamentos(response.data);
    });

    api.get('/count').then(response => {
      setCount(response.data);
    });
  }, [ orcamentos ]);

  async function handleUpdate(id: string) {
    await api.post(`/delete/${id}`);
  }; 

  return (
    <>
    <Header />
    <Container>
      <h2>Histórico - ( {count} Pedidos )</h2>

      <div style={{display: `${display}`}}><h2>Loading...</h2></div>
      
      <table>
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Orçamento</th>
            <th>Data Entrega</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          { orcamentos.map(orcamento => (
          <tr key={orcamento.id}>
            <td>{orcamento.empresa}</td>
            <td>{orcamento.orcamento}</td>
            <td>{new Date(orcamento.date).toLocaleDateString('pt-br')}</td>
            <td><button onClick={() => handleUpdate(orcamento.id)}>Recuperar</button></td>
          </tr>
          ))}          
        </tbody>
      </table>
    </Container>
    </>
  )
};

export default Historico;